import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { useTranslation } from "gatsby-plugin-react-i18next"
import binder1 from "../../assets/img/binder1.png"
import binder2 from "../../assets/img/binder2.png"
import binder3 from "../../assets/img/binder3.png"
import binder4 from "../../assets/img/binder4.png"
import binder5 from "../../assets/img/binder5.png"
import binder6 from "../../assets/img/binder6.png"

export default function BinderProjesi() {
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO title="Binder Derneği Projesi" />
      <div className="projeler-container page">
        <div className="ic-proje">
          <img src={binder1} alt="" className="proje-icGorsel" />
          <div className="proje-kunye">
            <span>{t("project-brand")}</span>: {t("binder-dernegi")}
            <br />
            <span>{t("project-sector")}</span>: {t("sivil-toplum")}
            <br />
            <span>{t("project-subject")}</span>: {t("kutlu-dogum-logo")}
            <br />
            <span>{t("project-artwork")}</span>: {t("es-sadik")}
          </div>
          <img src={binder2} alt="" className="proje-icGorsel" />
        </div>
        <div className="seperator"></div>
        <div className="ic-proje">
          <img src={binder3} alt="" className="proje-icGorsel" />
          <div className="proje-kunye">
            <span>{t("project-brand")}</span>: {t("binder-dernegi")}
            <br />
            <span>{t("project-sector")}</span>: {t("sivil-toplum")}
            <br />
            <span>{t("project-subject")}</span>: {t("kutlu-dogum-logo")}
            <br />
            <span>{t("project-artwork")}</span>: {t("el-mubellig")}
          </div>
          <img src={binder4} alt="" className="proje-icGorsel" />
        </div>
        <div className="seperator"></div>
        <div className="ic-proje">
          <img src={binder5} alt="" className="proje-icGorsel" />
          <div className="proje-kunye">
            <span>{t("project-brand")}</span>: {t("binder-dernegi")}
            <br />
            <span>{t("project-sector")}</span>: {t("sivil-toplum")}
            <br />
            <span>{t("project-subject")}</span>: {t("kutlu-dogum-logo")}
            <br />
            <span>{t("project-artwork")}</span>: {t("el-mustakim")}
          </div>
          <img src={binder6} alt="" className="proje-icGorsel" />
        </div>
      </div>
    </Layout>
  )
}
